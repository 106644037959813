<template>
  <picture v-not-lazy v-if="src && notLazy" key="static" class="picture" data-not-lazy>
    <source :data-srcset="cachedSrcSet" />
    <img v-lazy-load  :data-src="src" alt="" draggable="false" :width="width" :height="height" />
  </picture>

  <picture v-lazy-load  v-else-if="src" key="lazy" class="picture">
    <source :data-srcset="cachedSrcSet" />
    <img v-lazy-load  :data-src="src" alt="" draggable="false" :width="width" :height="height" />
  </picture>
</template>

<script setup lang="ts">
  const props = defineProps<{
    src?: string;
    notLazy?: boolean;
    width?: number;
    height?: number;
  }>();

  const { createSrcSet } = useProjectMethods();

  const cachedSrcSet = computed(() => createSrcSet(props.src));
</script>
